<div ngModelGroup="product_pricing" class="bg-white rounded-lg border px-4 py-6">
  <!-- PRICING -->
  <div>
    <h3 class="mb-6 font-semibold">
      {{ "common.pricing" | translate | sentenceCase }}
    </h3>
    <div class="flex items-end gap-3">
      <!-- SKU -->
      <div class="flex-[2] flex flex-col gap-2">
        <label class="text-sm font-medium" htmlFor="">
          {{ "common.sku" | translate | uppercase }}
          <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>
        <input
          required
          [(ngModel)]="productForm.sku"
          (ngModelChange)="onChangeSKU()"
          [placeholder]="managementPrefix + 'enter-sku' | translate | sentenceCase"
          class="h-10"
          name="sku"
          pInputText
          type="text"
          [readOnly]="isViewMode"
        />
      </div>
     
      
      <!-- PRICE -->
      <div class="flex-[2] flex flex-col gap-2">
        <label class="text-sm font-medium">
          {{ "common.price" | translate | sentenceCase }}
          <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>
        <div class="p-inputgroup">
          <p-inputNumber
            appMaxPriceValidator
            [(ngModel)]="productForm.price"
            [min]="0"
            [placeholder]="managementPrefix + 'enter-price' | translate | sentenceCase"
            [required]="true"
            class="h-10"
            name="price"
            [readonly]="isViewMode"
            [maxFractionDigits]="2"
          />
          <span
            class="px-3 py-2 border-y border-r rounded-tr rounded-br font-medium text-sm h-10 border-gray-300 flex items-center"
          >
            <img src="assets/icons/currency-icon.svg" alt="currency" />
          </span>
        </div>
      </div>
      <!-- SALE PRICE -->
      <!-- <div class="flex-[2] flex flex-col gap-2">
        <label class="text-sm font-medium">
          {{ managementPrefix + "sale-price" | translate | sentenceCase }}
          <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>
        <div class="p-inputgroup">
          <p-inputNumber
            appMaxPriceValidator
            [(ngModel)]="productForm.sale_price"
            [min]="0"
            [max]="productForm.price || 0"
            [placeholder]="managementPrefix + 'enter-sale-price' | translate | sentenceCase"
            [required]="true"
            class="h-10"
            name="sale_price"
            [readonly]="isViewMode"
          />
          <span
            class="px-3 py-2 border-y border-r rounded-tr rounded-br font-medium text-sm h-10 border-gray-300 flex items-center"
          >
            <img src="assets/icons/currency-icon.svg" alt="currency" />
          </span>
        </div>
      </div> -->
      <button
        (click)="handleApplyForAllVariants()"
        *ngIf="productForm.variants.length && !isViewMode"
        class="bg-blue-500 hover:bg-blue-600 text-white rounded-lg text-sm px-[14px] h-10"
      >
        {{ managementPrefix + "text-description.apply-for-all-variants" | translate | sentenceCase }}
      </button>
    </div>
    <!-- PRICING FORM -->
    <div [ngClass]="{ 'mt-4': productForm.variants.length }" class="flex gap-4 mb-6">
      <div
        *ngFor="let variant of productForm.variants; let i = index"
        class="bg-gray-50 rounded-xl flex flex-col gap-2 flex-1 p-4 max-w-[calc(50%_-_8px)]"
      >
        <ng-container>
          <div class="flex items-center gap-3">
            <div class="flex-1">
              <input
                #variantModel="ngModel"
                variantValidator
                [productForm]="productForm"
                [required]="true"
                [ngModel]="productForm.variants[i].key"
                (ngModelChange)="onVariantKeyChange($event, i)"
                [name]="'variant_key' + i"
                [placeholder]="managementPrefix + 'variant-name' | translate | sentenceCase"
                class="w-full h-10"
                pInputText
                [readOnly]="isViewMode"
              />
              <div
                *ngIf="variantModel.errors?.['duplicated']"
                class="leading-5 text-xs text-error-500 font-normal mt-1"
              >
                {{ managementPrefix + "validate.duplicated-variant" | translate }}
              </div>
            </div>
            <div *ngIf="!isViewMode" class="w-10 h-10 flex justify-center items-center">
              <span
                (click)="productForm.variants.splice(i, 1); handleConvertPricing()"
                class="pi pi-trash text-orange-dark-600 text-xl cursor-pointer"
              ></span>
            </div>
          </div>
          <div class="mt-4">
            <label class="text-gray-700 text-sm font-medium">
              {{ managementPrefix + "option-values" | translate | sentenceCase }}
            </label>
            <ul class="flex flex-col gap-3 mt-2 mb-4">
              <li *ngFor="let value of variant.values; let j = index">
                <div class="flex items-center gap-5">
                  <input
                    #optionModel="ngModel"
                    optionValidator
                    [variantIdx]="i"
                    [productForm]="productForm"
                    [optionIdx]="j"
                    [required]="true"
                    (ngModelChange)="onVariantValueChange($event, i, j)"
                    [ngModel]="productForm.variants[i].values[j].data"
                    [name]="'variant' + i + j"
                    [placeholder]="descriptionPrefix + 'enter-value' | translate | sentenceCase"
                    class="flex-1 h-9"
                    pInputText
                    type="text"
                    [readOnly]="isViewMode"
                  />

                  <div
                    *ngIf="!isViewMode"
                    (click)="onDeleteVariant(i, j)"
                    class="w-10 h-10 flex items-center justify-center cursor-pointer hover:bg-red-0 rounded-lg"
                  >
                    <i class="pi pi-times"></i>
                  </div>
                </div>
                <div
                  *ngIf="optionModel.errors?.['duplicated']"
                  class="leading-5 text-xs text-error-500 font-normal mt-1"
                >
                  {{ managementPrefix + "validate.duplicated-option" | translate }}
                </div>
              </li>
            </ul>
            <button
              *ngIf="productForm.variants[i].values.length < 10 && !isViewMode"
              (click)="onAddOption(i)"
              class="mt-2 flex items-center gap-1 justify-start text-blue-700 text-sm font-semibold"
              type="button"
            >
              <i class="pi pi-plus"></i>
              {{ descriptionPrefix + "add-option" | translate | sentenceCase }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
    <button
      *ngIf="productForm.variants.length < 2 && !isViewMode"
      (click)="onAddVariant()"
      class="flex items-center gap-2 justify-start text-blue-700 text-sm font-semibold"
      type="button"
    >
      <i class="pi pi-plus"></i>
      {{ managementPrefix + "add-variant" | translate | sentenceCase }}
    </button>
  </div>

  <!-- STOCKS -->
  <div *ngIf="productForm.variants.length" class="mt-6">
    <h3 class="mb-6 font-semibold">
      {{ managementPrefix + "variant-price" | translate | sentenceCase }}
    </h3>
    <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="productForm.pricings">
      <ng-template pTemplate="header">
        <tr>
          <th class="text-xs font-medium text-gray-600" style="min-width: 200px">
            {{ productForm.variants[0].key ? productForm.variants[0].key : ("N/A" | titlecase) }}
          </th>
          <th *ngIf="productForm.variants[1]" class="text-xs font-medium text-gray-600">
            {{ productForm.variants[1].key ? productForm.variants[1].key : ("N/A" | titlecase) }}
          </th>
          <th *ngIf="!isCreateMode" class="text-xs font-medium text-gray-600">
            {{ "common.app-sku" | translate: { appName: appName } }}
          </th>
          <th class="text-xs font-medium text-gray-600">
            {{ "common.sku" | translate | uppercase }}
            <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
          </th>
         
          <th class="text-xs font-medium text-gray-600">
            {{ "common.price" | translate | sentenceCase }}
            <span class="text-error-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
          </th>
          
        </tr>
      </ng-template>
      <ng-template let-pricing let-rowIndex="rowIndex" pTemplate="body">
        <tr>
          <!-- Images -->
          <td>
            <div class="flex gap-2 items-center">
              <button (click)="op.toggle($event)" class="w-20 h-20 rounded-2xl border overflow-hidden" type="button">
                <div *ngIf="pricing.imageIndex < 0" class="w-full h-full flex items-center justify-center">
                  <div class="imgage-placeholder w-10 h-10 p-2 bg-gray-100 rounded-full">
                    <i [class]="SoctripIcons.UPLOAD_01"></i>
                  </div>
                </div>
                <app-attachment-display
                  *ngIf="pricing.imageIndex >= 0"
                  [attachment]="attachmentsDisplay[pricing.imageIndex]"
                ></app-attachment-display>
              </button>
              <span class="py-2 text-gray-900 text-sm font-medium">
                {{ pricing.value }}
              </span>
              <p-overlayPanel #op>
                <h2 class="mb-2">
                  {{ descriptionPrefix + "select-image" | translate | sentenceCase }}
                </h2>
                <div class="grid grid-cols-3 gap-2">
                  <ng-container *ngIf="attachmentsDisplay.length > 0">
                    <div
                      (click)="productForm.pricings[rowIndex].imageIndex = i; op.toggle($event)"
                      *ngFor="let attachment of attachmentsDisplay; let i = index"
                      class="cursor-pointer rounded-xl border overflow-hidden w-[84px] h-[84px]"
                    >
                      <app-attachment-display [attachment]="attachment"></app-attachment-display>
                    </div>
                  </ng-container>
                  <div *ngIf="attachmentsDisplay.length == 0">
                    {{ descriptionPrefix + "there-are-no-image-yet" | translate | sentenceCase }}
                  </div>
                </div>
              </p-overlayPanel>
            </div>

            <div *ngIf="pricing.imageIndex >= 0 && !isViewMode" class="w-20 flex justify-between mt-2 gap-2">
              <div
                (click)="op.toggle($event)"
                class="py-[6px] px-[10px] border rounded-md hover:bg-gray-100 cursor-pointer flex-1 flex items-center justify-center"
              >
                <i [classList]="SoctripIcons.EDIT_05"></i>
              </div>
              <div
                (click)="productForm.pricings[rowIndex].imageIndex = -1"
                class="py-[6px] px-[10px] border rounded-md hover:bg-gray-100 cursor-pointer flex-1 flex items-center justify-center"
              >
                <i [classList]="SoctripIcons.TRASH_01"></i>
              </div>
            </div>
          </td>
          <!-- Option Variant 2 -->
          <td *ngIf="productForm.variants[1]">
            <ul *ngIf="pricing?.options && productForm.variants[1]?.key" class="flex flex-col gap-2">
              <li *ngFor="let option of pricing?.options; let optionIndex = index">
                <div class="flex items-center gap-3">
                  <input
                    #variantImageInput
                    class="hidden"
                    type="file"
                    accept="image/svg+xml, image/png, image/jpg, image/jpeg, image/bmp, image/webp, image/tiff, image/gif"
                    (change)="onUploadImage($event, rowIndex, optionIndex)"
                  />

                  <div class="cursor-pointer relative" (click)="variantImageInput.click()">
                    <ng-container *ngIf="!isViewMode">
                      <div
                        *ngIf="uploadingIds.includes(rowIndex + '-' + optionIndex)"
                        class="absolute bg-gray-200/50 w-full h-full left-0 top-0 flex items-center justify-center"
                      >
                        <i class="pi pi-spin pi-spinner"></i>
                      </div>
                      <div
                        *ngIf="!option.image"
                        class="h-10 w-10 border border-dashed flex items-center justify-center rounded-md"
                      >
                        <i *ngIf="!uploadingIds.includes(rowIndex + '-' + optionIndex)" class="sctr-icon-image-up"></i>
                      </div>
                    </ng-container>
                    <app-custom-image
                      *ngIf="option.image"
                      class="w-10 h-10 rounded-md overflow-hidden"
                      [src]="option.image?.id"
                      alt="Variant Image"
                    ></app-custom-image>
                  </div>

                  <span class="text-sm max-h-16 overflow-y-auto whitespace-normal break-words" style="max-width: 200px">
                    {{ option?.value }}
                  </span>
                </div>
              </li>
            </ul>
          </td>
          <!--SOCTRIP SKU -->
          <td *ngIf="!isCreateMode">
            <ul class="flex flex-col gap-5">
              <li *ngFor="let option of pricing?.options; let i = index">
                <input
                  [value]="productForm.pricings[rowIndex]['options'][i]['s_sku']"
                  class="h-9"
                  pInputText
                  type="text"
                  [readOnly]="true"
                  [disabled]="true"
                />
              </li>
            </ul>
            <!-- In Stocks -->
          </td>
          <!-- SKU -->
          <td>
            <ul class="flex flex-col gap-5">
              <li *ngFor="let option of pricing?.options; let i = index">
                <input
                  #skuModel="ngModel"
                  [readOnly]="isViewMode"
                  required
                  skuValidator
                  [productForm]="productForm"
                  [(ngModel)]="productForm.pricings[rowIndex]['options'][i]['sku']"
                  [name]="'sku' + rowIndex + i"
                  [placeholder]="descriptionPrefix + 'enter-sku' | translate | sentenceCase"
                  class="h-9"
                  pInputText
                  type="text"
                />
                <div *ngIf="skuModel.errors?.['duplicated']" class="leading-5 text-xs text-error-500 font-normal mt-1">
                  {{ managementPrefix + "validate.duplicated-sku" | translate }}
                </div>
              </li>
            </ul>
            <!-- In Stocks -->
          </td>
      
          <!-- Price -->
          <td style="min-width: 200px">
            <ul class="flex flex-col gap-5">
              <li *ngFor="let option of pricing?.options; let i = index">
                <ng-container>
                  <div class="p-inputgroup">
                    <input
                      required
                      appMaxPriceValidator
                      [readOnly]="isViewMode"
                      [(ngModel)]="productForm.pricings[rowIndex]['options'][i]['price']"
                      [name]="'price' + rowIndex + i"
                      [placeholder]="descriptionPrefix + 'enter-price' | translate | sentenceCase"
                      [required]="true"
                      class="h-9"
                      pInputText
                      type="number"
                    />

                    <span
                      class="px-3 py-2 border-y border-gray-300 border-r rounded-tr rounded-br font-normal h-9 flex items-center text-gray-500 text-sm"
                    >
                      <img src="assets/icons/currency-icon.svg" alt="currency" />
                    </span>
                  </div>
                </ng-container>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
