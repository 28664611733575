import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { ChipsAddEvent } from 'primeng/chips';

import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { ProductGet } from 'src/app/core/models/interfaces/product/product-detail';
import { ProductFormType } from '../../pages/product-edit/product-edit.model';
import { OTHERS } from 'src/app/core/constants/category.config';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class GeneralInfoComponent {
  SoctripIcons = SoctripIcons;
  actionPrefix = 'section-action.';
  managementPrefix = 'section-product-n-food-management.';
  descriptionPrefix = 'text-description.';
  categoryPrefix = 'section-product-n-food-category.';

  @Input({ required: true }) productForm: ProductFormType;
  @Input({ required: true }) attachments: any[];
  @Input({ required: true }) categoryGlobalData: any[];
  @Input() productData: ProductGet;
  @Input() isViewMode: boolean = false;
  @Input() isIframe: boolean = false;
  @Input() isSubmitted = false;

  @Output() attachmentsChange = new EventEmitter<any[]>();

  categoriesGlobalList: any;
  selectedGlobalCategory?: any;
  breadCrumbNames: any[] = [];
  isSelectionDropped = false;
  others = OTHERS;
  commandData: any = { key: '', node: {}, list: [] };

  ngOnChanges(): void {
    if (this.categoryGlobalData) this.categoriesGlobalList = [this.categoryGlobalData];
    this.sortCategories();

    if (this.productData?.catalogs?.length) {
      let results = this.productData.catalogs?.[0].reference;
      results = results.replaceAll('>', ' > ');
      this.breadCrumbNames = []
      this.breadCrumbNames.push(results);
    }
  }

  sortCategories() {
    this.categoriesGlobalList?.forEach((categories: any[]) => {
      categories.sort((a: any, b: any) => {
        if (a.name.toUpperCase() === this.others) return 1;
        if (b.name.toUpperCase() === this.others) return -1;
        return 0;
      });
    });
  }

  hoverCategory(event: any, category: any) {
    event.stopPropagation();
    this.categoriesGlobalList.splice(category.depth + 1);
    if (category.sub_catalogs) this.categoriesGlobalList.push(category.sub_catalogs);
  }

  selectCategory(event: any, category: any) {
    event.stopPropagation();
    this.selectedGlobalCategory = category;
    if (category) {
      this.breadCrumbNames = this.getBreadCrumbNames(category.id);
      this.productForm.category = category;
    } else {
      this.breadCrumbNames = [];
    }

    this.isSelectionDropped = false;
  }

  getBreadCrumbNames(id: string, list: any[] = this.categoryGlobalData) {
    const array = this.getFlattenNestedCategory(id, list);
    return array.map((item: any) => item.name);
  }

  getFlattenNestedCategory(id: string, list: any[] = this.categoryGlobalData) {
    let result = [];
    let node = this.findGlobalCategory(id, list);
    while (node) {
      result.unshift(node);
      node = this.findGlobalCategory(node.parent_id, list);
    }
    return result;
  }

  findGlobalCategory(id: string, list: any[] = this.categoryGlobalData): any {
    let result = undefined;
    for (const item of list) {
      if (item.id === id) return item;
    }
    for (const item of list) {
      const children = item.sub_catalogs;
      if (children?.length) result = this.findGlobalCategory(id, children);
      if (result) break;
    }
    return result;
  }

  onAddChip(event: ChipsAddEvent) {
    const MAX_LENGTH_HASHTAG = 100;
    if (event.value.length > MAX_LENGTH_HASHTAG) {
      this.productForm.hashtags.pop();
      this.productForm.hashtags.push(event.value.substring(0, MAX_LENGTH_HASHTAG));
    }
  }
  onAttachmentsChange(e: any[]) {
    this.attachments = e;
    this.attachmentsChange.emit(this.attachments);
  }

  onSelectCategory(event: any) {
    const type = event.node?.type;
    if (type === 'E_TICKET') {
      const { weight, width, length, height } = this.productForm.parcel;
      this.productForm.parcel = {
        weight: weight || 100,
        width: width || 100,
        length: length || 100,
        height: height || 100,
      };
    }
  }

  checkCreatedEditedBy() {
    let results = false;
    if (this.productData.status === 'NEW') {
      if (this.productData?.shopDetailDTO?.created_by === this.productData?.created_by) {
        results = true;
      }
    }

    if (this.productData.status === 'EDIT') {
      if (this.productData?.shopDetailDTO?.created_by === this.productData?.updated_by) {
        results = true;
      }
    }

    return results;
  }
}
